import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@logic-suite/shared/auth';

@Component({
  selector: 'flx-e500',
  template: `
    <h1 translate>Service unavailable</h1>
    <h4 translate>We are sorry, FLX is currently unavailable.</h4>
    <p translate>We are working on a solution.</p>
    <p translate>
      If you want more information, please contact your system administrator, or
      <a href="mailto:post@noova.no">post&#64;noova.no</a> directly.
    </p>
    <footer>
      <a (click)="onRetry()" mat-raised-button class="bButton" translate>Try again?</a>
    </footer>
  `,
  styles: [
    `
      :host {
        display: block;
        position: fixed;
        background: var(--background);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 !important;
        z-index: 1000;
        place-content: center;
        place-items: center;
        .mat-mdc-raised-button ::ng-deep .mdc-button__label {
          padding-left: 2rem;
          padding-right: 2rem;
        }
        h1 {
          margin-bottom: 1rem;
        }
        h4 {
          margin-bottom: 2rem;
        }
        p {
          text-align: center;
          margin: 0;
        }
        footer {
          margin-top: 3rem;
        }
      }
    `,
  ],
})
export class E500Component {
  constructor(private auth: AuthService, private router: Router) {}

  onRetry() {
    this.router
      .navigate(['/'])
      .then(redirected => {
        if (!redirected) this.auth.logout();
      })
      .catch(() => this.auth.logout());
  }
}
