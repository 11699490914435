<!-- Ask if user wants a tour, if there is a flow to show -->
@if (tourAvailable) {
  <div class="bar-container">
    <div class="bar page">
      <a (click)="playTour()">{{ 'ask ' + activeFlow()?.flowName | translate }}</a>
      <button mat-icon-button (click)="endTour()" [title]="'End tour' | translate">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
}

<main class="page">
  <!-- Show route -->
  <router-outlet></router-outlet>
</main>

@if (!isOnline()) {
  <div class="alert-box">
    {{ 'You have lost your connectivity' | translate }}
  </div>
}
@if (isLoggedIn()) {
  <footer [ngClass]="{ online: isOnline() }">
    <div class="page">
      <a [routerLink]="['home']" routerLinkActive #home="routerLinkActive">
        <flx-icon-home [selected]="home.isActive"></flx-icon-home>
        <span class="sr-only">{{ 'Booking' | translate }}</span>
      </a>
      <a [routerLink]="['colleagues']" routerLinkActive #social="routerLinkActive">
        <flx-icon-colleagues [selected]="social.isActive"></flx-icon-colleagues>
        <span class="sr-only">{{ 'My colleagues' | translate }}</span>
      </a>
      <a [routerLink]="['user']" routerLinkActive #user="routerLinkActive">
        <flx-user [selected]="user.isActive"></flx-user>
        <span class="sr-only">{{ 'Profile/Preferences' | translate }}</span>
      </a>

      <!-- Company switch -->
      @if (selectedCustomer && emp.customers.length > 1) {
        <a>
          <button
            class="customerLogo"
            type="button"
            aria-label="Choose customer"
            mat-button
            [matMenuTriggerFor]="customerOptions"
          >
            <ng-container
              [ngTemplateOutlet]="selectedCustomer.logoUrl[themeBase()] != null ? customerLogoTpl : customerNameTpl"
              [ngTemplateOutletContext]="{ customer: selectedCustomer }"
            ></ng-container>
          </button>

          <!-- List of options -->
          <mat-menu #customerOptions="matMenu" [classList]="'customer-selector'">
            <header>{{ 'Choose customer' | translate }}</header>
            @for (customer of emp.customers; track customer.customerID) {
              <button type="button" class="customerLogo" (click)="selectCustomer(customer)">
                <ng-container
                  [ngTemplateOutlet]="customer.logoUrl[themeBase()] != null ? customerLogoTpl : customerNameTpl"
                  [ngTemplateOutletContext]="{ customer, withName: true }"
                ></ng-container>
              </button>
            }
          </mat-menu>
        </a>
      }
    </div>
  </footer>
}

<!-- Show logo -->
<ng-template #customerLogoTpl let-customer="customer" let-withName="withName">
  <img class="logo" [attr.src]="customer.logoUrl[themeBase()]" alt="Company logo" />
  @if (withName) {
    <div class="full-name">{{ customer.customerName }}</div>
  }
</ng-template>

<!-- Show only name, abbreviated -->
<ng-template #customerNameTpl let-customer="customer" let-withName="withName">
  <div class="logo">{{ customer.customerName | abbreviate: 3 }}</div>
  @if (withName) {
    <div class="full-name">{{ customer.customerName }}</div>
  }
</ng-template>
