import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, switchMap } from 'rxjs';
import { EmployeeService } from './employee.service';
import { EmployeeCustomer } from './profile.model';

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {
  constructor(private employee: EmployeeService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/api/flex') && !req.url.endsWith('/flex/Employee')) {
      return this.employee.getCustomer$().pipe(
        filter(cust => !!cust),
        switchMap((customer: EmployeeCustomer) => {
          req = req.clone({
            setHeaders: { 'X-CustomerID': `${customer.customerID}` },
          });
          return next.handle(req);
        }),
      );
    }
    return next.handle(req);
  }
}
