import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403 && !request.headers.has('noIntercept')) {
          this.router.navigate(['/403']);
        }
        if (err.status != 200 && request.url.includes('/api/flex/Employee')) {
          this.router.navigate(['/500']);
        }
        return throwError(() => err);
      }),
    );
  }
}
