import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Workbox } from 'workbox-window';

import { errorToString, logger } from '@logic-suite/shared/utils';

import { ResetComponent } from '@logic-suite/shared/auth/reset.component';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (location.pathname === '/reset') {
  // Skip bootstrapping anything other than the reset component
  // when the reset route is requested
  bootstrapApplication(ResetComponent).catch(err => console.error(err));
} else {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => loadServiceWorker())
    .catch(err => {
      const html = `
      <h1>Failed to start application</h1>
      <p>${errorToString(err)}</p>
    `;
      (document.getElementById('app-error') || document.body).innerHTML = html;
    });
}

/**
 * Load service worker
 * This will also listen for updates and act accordingly
 */
function loadServiceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      const wb = new Workbox('/ngsw-worker.js', { scope: '/', updateViaCache: 'none' });

      // Log out events in sequence:
      // [installing -> installed -> redundant -> waiting -> activating -> controlling -> activated]
      wb.addEventListener('installing', () => logger('Installing service worker'));
      wb.addEventListener('installed', () => logger('Installed!'));
      wb.addEventListener('redundant', () => logger('Redundant service worker found'));
      wb.addEventListener('waiting', () => {
        logger('Waiting to activate service worker <- Auto skip');
        wb.messageSkipWaiting();
      });
      wb.addEventListener('activating', () => logger('Activating service worker'));
      wb.addEventListener('controlling', () => {
        logger('Service worker controlling page <- Reloading');
        window.location.reload();
      });
      wb.addEventListener('activated', () => logger('New service worker activated!'));

      // Register the service worker
      wb.register({ immediate: true })
        .then(reg => {
          if (!reg) throw 'Service worker not registered!';

          // Check for updates every 5 minutes
          setInterval(() => wb.update(), 1 * 60 * 1000);
        })
        .catch(err => logger(errorToString(err), 'App', true));
    } catch (err) {
      logger(errorToString(err), 'App', true);
    }
  } else {
    logger('Service worker not supported', 'App', true);
  }
}
